<template>
  <div class="house-detail">
    <app-detail :opt="opt" ref="detail"></app-detail>
  </div>
</template>
<script>
let priceVali = (rule, value, callback) => {
  var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/
  if (!reg.test(value)) {
    callback(
      new Error("请输入正数，且小数点后最多保留2位")
    );
  } else if(!(value > 0)){
    callback(
      new Error("扣款金额应大于0")
    );
  } else{
    callback();
  }
};
export default {
  data() {
    let _this = this;
    return {
      rentalStyle: "",
      opt: {
        title: "房东同意退房",
        form: [
          {
            label: "房客姓名",
            key: "realname",
            disabled: true,
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "房源",
            key: "address",
            disabled: true,
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "退房日期",
            key: "quitDate",
            disabled: true,
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "应退金额（元）",
            key: "checkoutSum",
            disabled: true,
            isCol50: true,
            rules: [{ required: true }]
          },
          {
            label: "是否有扣款",
            key: "deductionDesc",
            type: "select",
            value: "0",
            opt: {
              list: [
                {
                  label: "否",
                  value: "0"
                },
                {
                  label: "是",
                  value: "1"
                }
              ]
            },
            isCol50: true,
            rules: [{ required: true }],
            disabled: false
          },
          {
            label: "扣款金额",
            key: "deductionFee",
            isCol50: true,
            rules: [{
              required: true,
              trigger: "change",
              validator: priceVali,
            }],
            filters: {
              key: "deductionDesc",
              value: "1"
            }
          },
          {
            label: "扣款原因",
            key: "itemDescription",
            type: "textarea",
            rules: [{ required: true }],
            filters: {
              key: "deductionDesc",
              value: "1"
            }
          },
          {
            label: "上传凭证",
            key: "imgs",
            type: "upload-img-form",
            rules: [{ required: true }],
            filters: {
              key: "deductionDesc",
              value: "1"
            },
            opt: {
              alt:
                "支持上传1-4张图片，支持png、jpg格式 *第一张为主照片，供手机端首页展示",
              url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
              urlKey: "picPath",
              limit: 4,
              upload(data) {
                console.log(data);
                return true;
              },
              remove(file, fileList) {
                console.log(file, fileList);
                return true;
              }
            }
          }
        ],
        buttons: [
          {
            name: "提交",
            show: ["edit"],
            cb(form) {
              let dto = {
                roomId: _this.$route.query.roomId,
                leaseId: _this.$route.query.leaseId,
                contractId: _this.$route.query.contractId,
              };
              if (form.deductionDesc == "1") {
                dto.deductionPic = [];
                form.imgs.forEach((data, index) => {
                  dto.deductionPic.push(data.url);
                });
                dto.deductionDesc = form.itemDescription;
                dto.deductionFee = form.deductionFee;
              }
              if (_this.$route.query.rentalStyle == "1" || _this.$route.query.rentalStyle == "短租" ) {
                dto.shortRentId = _this.$route.query.shortRentId;
              }
              else if (_this.$route.query.rentalStyle == "0" || _this.$route.query.rentalStyle == "3" || _this.$route.query.rentalStyle == "长租" || _this.$route.query.rentalStyle == "长短租") {
                dto.contractId = _this.$route.query.shortRentId;
              }
              _this.post("/landlord-service/leasingCenter/agreementForSurrender",dto,{
                isUseResponse: true
              }).then(res =>{
                  if(res.data.code == 0) {
                    _this.$message({
                      showClose: true,
                      message: "操作成功",
                      type: "success"
                    });
                    _this.$back();
                  }
                })
            }
          }
        ],
        get(opt) {
          // if (Number(_this.$route.query.checkoutSum).toFixed(2) == "0.00") {
          //   _this.opt.form[4].disabled = true;
          // }else {
          //   _this.opt.form[4].disabled = false;
          // }
          let res = {
            realname: _this.$route.query.realname,
            address: _this.$route.query.address,
            quitDate: _this.$route.query.quitDate,
            checkoutSum: _this.$route.query.checkoutSum,
            isPlan: _this.$route.query.isPlan,
            deductionDesc: "0",
            deductionFee: "",
            itemDescription: "",
            imgs: []
          };
          _this.opt.form.forEach(item => {
            if (item.key == 'deductionDesc') {
              if (_this.$route.query.isPlan == 1) {
                item.disabled = true
              } else {
                item.disabled = false
              }
            }
          })
          opt.cb(res);
        }
      }
    };
  },
  created: function() {
    console.log("house-detail created!!");
    // console.log(this.opt.form);
  }
};
</script>
<style lang="scss" scoped>
tr {
  text-align: center;
  td {
    width: 300px;
  }
}
span {
  font-size: 14px;
  color: #303133;
}
el-button {
  width: 60%;
}
.grid-content {
  width: 60%;
  text-align: center;
  border-radius: 4px;
  min-height: 36px;
  line-height: 36px;
}
.bg-purple-dark {
  background: #99a9bf;
}
</style>


